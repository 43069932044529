import { Box } from "@mui/system";
import { ReactElement } from "react";
import { useTranslate } from "react-admin";
import { FrequencyType } from "../lib/types";

export function Frequency({ frequency: fr }: { frequency: FrequencyType }): ReactElement {
  const translate = useTranslate();

  if (!fr || !fr.wcpm) {
    return <Box sx={{ marginLeft: "1em" }}>No frequencies found</Box>;
  } else {
    return (
      <Box>
        <Box component={"span"} sx={{ fontWeight: "bold" }} title={translate("stats.frequency.description")}>
          {translate("stats.frequency.title")}
        </Box>
        {fr.wcpm && (
          <Box component={"div"} sx={{ marginLeft: "0.7em" }} title={translate("stats.frequency.wcpm_description")}>
            WCPM: {fr.wcpm}
          </Box>
        )}
        {fr.wcdp && (
          <Box component={"div"} sx={{ marginLeft: "0.7em" }} title={translate("stats.frequency.wcdp_description")}>
            WCDP: {fr.wcdp}%
          </Box>
        )}
      </Box>
    );
  }
}
