import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, ClassNameMap, Divider, Typography } from "@mui/material";
import _ from "lodash";
import { useTranslate } from "ra-core";
import { ReactElement, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useAppSelector } from "../../../app/hooks";
import { TokenDetailsState } from "../../../features/ui/uiSlice";
import { hasCharacters } from "../../../lib/funclib";
import { DefinitionState } from "../../../lib/types";
import DiscoverableWord from "../../DiscoverableWord";
import Actions from "./Actions";
import Definitions from "./Definitions";
import Infos from "./Infos";
import Substrings from "./Substrings";
import Synonyms from "./Synonyms";

type Props = {
  tokenDetails: TokenDetailsState;
  definition: DefinitionState;
  otherDefinitions: DefinitionState[];
  classes: ClassNameMap<string>;
};

const useSummaryStyles = makeStyles()(() => ({
  root: {
    "&&": {
      minHeight: 0,
      paddingBottom: 0,
    },
  },
  content: {
    "&&": {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  expanded: {
    "&&": {
      marginBottom: 0,
      marginTop: 0,
    },
  },
}));

export default function DefContainer({ tokenDetails, definition, classes, otherDefinitions }: Props): ReactElement {
  const fromLang = useAppSelector((state) => state.userData.user.fromLang);
  const [lemmaDef, setLemmaDef] = useState<DefinitionState>();
  const [remainingDefs, setRemainingDefs] = useState<DefinitionState[]>([]);
  const otherClasses = useSummaryStyles();
  const translate = useTranslate();
  useEffect(() => {
    const [lds, others] = _.partition(otherDefinitions, { graph: tokenDetails.token.l });
    setLemmaDef(lds[0]);
    // remove the defs that are just bing defaults - it gives something for everthing, so it is just noise here
    setRemainingDefs(
      others.filter((x) => {
        return !(
          // x.graph === x.graph.toUpperCase() &&
          (
            x.providerTranslations.filter((x) => x.posTranslations.length > 0 && !["mst", "fbk"].includes(x.provider))
              .length === 0
          )
        );
      }),
    );
  }, [otherDefinitions]);
  return (
    <Box className={classes.container}>
      <Infos definition={lemmaDef || definition} tokenDetails={tokenDetails} />
      <Synonyms token={tokenDetails.token} definition={definition} />
      <Divider />
      <Actions className={classes.actions} tokenDetails={tokenDetails} definition={definition} />
      <Definitions definition={definition} classes={classes} />
      {(remainingDefs.length > 0 || lemmaDef) &&
        [lemmaDef, ...remainingDefs]
          .filter((x) => x)

          .map((rDef) => {
            return (
              <Accordion defaultExpanded={rDef!.graph === lemmaDef?.graph} disableGutters key={rDef!.id}>
                <AccordionSummary classes={otherClasses.classes} expandIcon={<ExpandMoreIcon />}>
                  <Box>
                    {rDef!.graph === lemmaDef?.graph && <span>{translate("widgets.popup.headword")}: </span>}
                    <DiscoverableWord graph={rDef!.graph} newTab />
                  </Box>
                </AccordionSummary>
                <AccordionDetails classes={otherClasses.classes}>
                  <Definitions definition={rDef!} classes={classes} />
                </AccordionDetails>
              </Accordion>
            );
          })}
      {hasCharacters(fromLang) && tokenDetails.token.l.length > 1 && (
        <>
          <Divider /> <Substrings token={tokenDetails.token} fromLang={fromLang} />
        </>
      )}
    </Box>
  );
}
