import { InitialEditorStateType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { lexicalEditorTheme } from "./editorTheme";
import { ReactElement, RefObject, useEffect } from "react";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { LexicalEditor } from "lexical";

export interface Props {
  namespace: string;
  editorRef: RefObject<LexicalEditor>;
  editorState?: string;
}

export default function LexicalViewer({
  namespace,
  editorRef,
  editorState,
  children,
}: React.PropsWithChildren<Props>): ReactElement {
  useEffect(() => {
    if (editorRef.current && editorState) {
      const newEditorState = editorRef.current.parseEditorState(editorState);
      editorRef.current.setEditorState(newEditorState);
    }
  }, [editorState]);
  return (
    <>
      <LexicalComposer
        initialConfig={{
          theme: lexicalEditorTheme,
          namespace,
          editorState: editorState || undefined,
          onError: (message) => console.error(message),
          editable: false,
          nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
          ],
        }}
      >
        <EditorRefPlugin editorRef={editorRef} />
        <RichTextPlugin
          contentEditable={<ContentEditable style={{ textAlign: "left" }} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <>{children}</>
      </LexicalComposer>
    </>
  );
}
