import { LexicalEditor } from "lexical";
import { ReactElement, useEffect, useRef } from "react";
import { CardType } from "../lib/types";
import LexicalViewer from "./lexical/LexicalViewer";

interface Props {
  card: CardType;
  defaultElements: ReactElement[];
}

export default function MeaningText({ card, defaultElements }: Props): ReactElement {
  const viewer = useRef<LexicalEditor>(null);
  // useEffect(() => {
  //   console.log("was", card, defaultElements);
  //   if (card.front) {
  //     viewer.current?.setEditorState(viewer.current.parseEditorState(card.front));
  //   }
  // }, [card]);

  return card.front ? (
    <LexicalViewer namespace="meaning" editorRef={viewer} editorState={card.front} />
  ) : (
    <>{defaultElements}</>
  );
}
