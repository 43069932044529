import {
  DIVIDER,
  LOW_PRIORITY,
  RICH_TEXT_OPTIONS,
  RICH_TEXT_OPTIONS_OBJECTS,
  RichTextAction,
} from "./ToolbarConstants";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useTranslate } from "ra-core";
import { useEffect, useState } from "react";
import { useKeyBindings } from "./hooks/useKeyBindings";

function Divider() {
  return <Box sx={{ width: "1px", bgcolor: "#aaa", margin: "0 3px", h: 4 }}></Box>;
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [disableMap, setDisableMap] = useState<{ [id: string]: boolean }>({
    [RichTextAction.Undo]: true,
    [RichTextAction.Redo]: true,
  });
  const [selectionMap, setSelectionMap] = useState<{ [id: string]: boolean }>({});

  const translate = useTranslate();
  function updateToolbar() {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const newSelectionMap = {
        [RichTextAction.Bold]: selection.hasFormat(RichTextAction.Bold),
        [RichTextAction.Italics]: selection.hasFormat(RichTextAction.Italics),
        [RichTextAction.Underline]: selection.hasFormat(RichTextAction.Underline),
        [RichTextAction.Strikethrough]: selection.hasFormat(RichTextAction.Strikethrough),
        [RichTextAction.Superscript]: selection.hasFormat(RichTextAction.Superscript),
        [RichTextAction.Subscript]: selection.hasFormat(RichTextAction.Subscript),
        [RichTextAction.Highlight]: selection.hasFormat(RichTextAction.Highlight),
      };
      setSelectionMap(newSelectionMap);
    }
  }

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (payload) => {
          updateToolbar();
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setDisableMap((prevDisableMap) => ({
            ...prevDisableMap,
            undo: !payload,
          }));
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setDisableMap((prevDisableMap) => ({
            ...prevDisableMap,
            redo: !payload,
          }));
          return false;
        },
        LOW_PRIORITY,
      ),
    );
  }, [editor]);

  const onAction = (id: RichTextAction) => {
    editor.dispatchCommand(RICH_TEXT_OPTIONS_OBJECTS[id].command!, RICH_TEXT_OPTIONS_OBJECTS[id].payload);
  };

  useKeyBindings({ onAction });

  return (
    <Box sx={{ display: "flex" }}>
      {RICH_TEXT_OPTIONS.map((id, index) =>
        id === DIVIDER ? (
          <Divider key={index} />
        ) : (
          <IconButton
            key={id}
            sx={{ padding: "3px", height: "1em" }}
            onClick={() => onAction(id as RichTextAction)}
            disabled={disableMap[id]}
            aria-label={RICH_TEXT_OPTIONS_OBJECTS[id].label}
            size="small"
            color={selectionMap[id] ? "secondary" : undefined}
          >
            {RICH_TEXT_OPTIONS_OBJECTS[id].icon}
          </IconButton>
        ),
      )}
    </Box>
  );
}
