import TextCenter from "@mui/icons-material/FormatAlignCenter";
import Justify from "@mui/icons-material/FormatAlignJustify";
import JustifyLeft from "@mui/icons-material/FormatAlignLeft";
import JustifyRight from "@mui/icons-material/FormatAlignRight";
import TypeBold from "@mui/icons-material/FormatBold";
import TypeItalic from "@mui/icons-material/FormatItalic";
import TypeStrikethrough from "@mui/icons-material/FormatStrikethrough";
import TypeUnderline from "@mui/icons-material/FormatUnderlined";
import Highlighter from "@mui/icons-material/Highlight";
import Subscript from "@mui/icons-material/Subscript";
import Superscript from "@mui/icons-material/Superscript";
import ArrowClockwise from "@mui/icons-material/RedoOutlined";
import ArrowCounterclockwise from "@mui/icons-material/UndoOutlined";

import {
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalCommand,
  REDO_COMMAND,
  TextFormatType,
  UNDO_COMMAND,
} from "lexical";
import { ReactElement } from "react";

export enum RichTextAction {
  Bold = "bold",
  Italics = "italic",
  Underline = "underline",
  Strikethrough = "strikethrough",
  Superscript = "superscript",
  Subscript = "subscript",
  Highlight = "highlight",
  LeftAlign = "left",
  CenterAlign = "center",
  RightAlign = "right",
  JustifyAlign = "justify",
  Undo = "undo",
  Redo = "redo",
}
export const DIVIDER = "divider";

export const RICH_TEXT_OPTIONS: (typeof DIVIDER | RichTextAction)[] = [
  RichTextAction.Bold,
  RichTextAction.Italics,
  RichTextAction.Underline,
  DIVIDER,
  RichTextAction.Highlight,
  RichTextAction.Strikethrough,
  RichTextAction.Superscript,
  RichTextAction.Subscript,
  DIVIDER,
  RichTextAction.LeftAlign,
  RichTextAction.CenterAlign,
  RichTextAction.RightAlign,
  RichTextAction.JustifyAlign,
  DIVIDER,
  RichTextAction.Undo,
  RichTextAction.Redo,
];

export const RICH_TEXT_OPTIONS_OBJECTS: Record<
  RichTextAction,
  {
    command: LexicalCommand<TextFormatType> | LexicalCommand<void> | LexicalCommand<ElementFormatType>;
    icon: ReactElement;
    label: string;
    payload?: TextFormatType | ElementFormatType;
  }
> = {
  [RichTextAction.Bold]: {
    icon: <TypeBold />,
    label: "Bold",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Bold,
  },
  [RichTextAction.Italics]: {
    icon: <TypeItalic />,
    label: "Italics",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Italics,
  },
  [RichTextAction.Underline]: {
    icon: <TypeUnderline />,
    label: "Underline",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Underline,
  },
  [RichTextAction.Highlight]: {
    icon: <Highlighter />,
    label: "Highlight",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Highlight,
  },
  [RichTextAction.Strikethrough]: {
    icon: <TypeStrikethrough />,
    label: "Strikethrough",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Strikethrough,
  },
  [RichTextAction.Superscript]: {
    icon: <Superscript />,
    label: "Superscript",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Superscript,
  },
  [RichTextAction.Subscript]: {
    icon: <Subscript />,
    label: "Subscript",
    command: FORMAT_TEXT_COMMAND,
    payload: RichTextAction.Subscript,
  },
  [RichTextAction.LeftAlign]: {
    icon: <JustifyLeft />,
    label: "Align Left",
    command: FORMAT_ELEMENT_COMMAND,
    payload: RichTextAction.LeftAlign,
  },
  [RichTextAction.CenterAlign]: {
    icon: <TextCenter />,
    label: "Align Center",
    command: FORMAT_ELEMENT_COMMAND,
    payload: RichTextAction.CenterAlign,
  },
  [RichTextAction.RightAlign]: {
    icon: <JustifyRight />,
    label: "Align Right",
    command: FORMAT_ELEMENT_COMMAND,
    payload: RichTextAction.RightAlign,
  },
  [RichTextAction.JustifyAlign]: {
    icon: <Justify />,
    label: "Align Justify",
    command: FORMAT_ELEMENT_COMMAND,
    payload: RichTextAction.JustifyAlign,
  },
  [RichTextAction.Undo]: {
    icon: <ArrowCounterclockwise />,
    label: "Undo",
    command: UNDO_COMMAND,
  },
  [RichTextAction.Redo]: {
    icon: <ArrowClockwise />,
    label: "Redo",
    command: REDO_COMMAND,
  },
};

export const LOW_PRIORITY = 1;
