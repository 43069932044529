import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { useAppSelector } from "../app/hooks";
import { AnalysisAccuracy } from "../lib/types";
import ContentAnalysisAccuracy from "./ContentAnalysisAccuracy";
import { platformHelper } from "../app/createStore";

export function ContentStatsAccuracyField(_: { label?: string }) {
  const record = useRecordContext();
  const fromLang = useAppSelector((state) => state.userData.user.fromLang);
  const [accuracy, setAccuracy] = useState<AnalysisAccuracy | null>();

  useEffect(() => {
    let importId = "";
    if (record) {
      if (Object.hasOwn(record, "theImport")) {
        importId = record.theImport;
      } else {
        importId = record.id.toString();
      }
      (async function () {
        if (!importId) return;
        const locStats: AnalysisAccuracy | null = await platformHelper.getContentAccuracyStatsForImport({
          importId,
          fromLang,
        });
        setAccuracy(locStats);
      });
    }
  }, [record]);
  return accuracy ? <ContentAnalysisAccuracy accuracy={accuracy} proxy={platformHelper} /> : <></>;
}
