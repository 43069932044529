import { ClassNameMap, Divider } from "@mui/material";
import { Fragment, ReactElement, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { orderTranslations, toPosLabels } from "../../../lib/libMethods";
import { DefinitionType, KnownLanguage, PosTranslationsType, ProviderTranslationType } from "../../../lib/types";
import { ReaderConfigContext } from "../../ReaderConfigProvider";
import { Translate, useTranslate } from "react-admin";

type Props = {
  definition: DefinitionType;
  classes: ClassNameMap<string>;
};

function getEntry(translation: PosTranslationsType, translate: Translate, toLang: KnownLanguage, compact: boolean) {
  return (
    <>
      {compact && translation.posTag.toLocaleLowerCase() === "other"
        ? ""
        : translation.posTag
            .split("/")
            .map((x) => translate(toPosLabels(x, toLang)))
            .join("/") + ": "}
      {translation.sounds ? ` ${translation.sounds}` : ""}
      <span>{translation.values.join(", ")}</span>
    </>
  );
}

export default function Definitions({ definition, classes }: Props): ReactElement {
  const toLang = useAppSelector((state) => state.userData.user.toLang);
  const dictionaries = useAppSelector((state) => state.dictionary);
  const { readerConfig } = useContext(ReaderConfigContext);
  const [orderedTranslations, setOrderedTranslations] = useState<ProviderTranslationType[]>([]);
  const translate = useTranslate();
  useEffect(() => {
    setOrderedTranslations(orderTranslations(definition.providerTranslations, readerConfig.translationProviderOrder));
  }, [readerConfig.translationProviderOrder, definition]);

  return (
    <>
      {orderedTranslations.map((provider) => {
        return (
          <Fragment key={provider.provider}>
            <Divider />
            <div className={classes.source} key={provider.provider}>
              <div className={classes.sourceName}>
                {dictionaries[provider.provider] || provider.provider}
                {provider.posTranslations.length === 1 && (
                  <span>: {getEntry(provider.posTranslations[0], translate, toLang, true)}</span>
                )}
              </div>
              {provider.posTranslations.length > 1 &&
                provider.posTranslations.map((translation) => {
                  return (
                    <div
                      key={provider.provider + translation.posTag + translation.sounds}
                      className={classes.sourcePos}
                    >
                      {getEntry(translation, translate, toLang, false)}
                    </div>
                  );
                })}
            </div>
          </Fragment>
        );
      })}
    </>
  );
}
