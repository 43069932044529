import styled from "@emotion/styled";
import { $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Typography } from "@mui/material";
import { $getRoot, EditorState, LexicalEditor } from "lexical";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslate } from "react-admin";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import { CardType, DefinitionType, ProviderTranslationType } from "../lib/types";
import { lexicalEditorTheme } from "./lexical/editorTheme";
import LexicalViewer from "./lexical/LexicalViewer";
import { MaxLengthPlugin } from "./lexical/MaxLengthPlugin";
import ToolbarPlugin from "./lexical/ToolbarPlugin";
import PosItems from "./PosItems";

const MuiContentEditable = styled(ContentEditable)({
  minHeight: 200,
  width: "100%",
  padding: "0 8px",
  borderRadius: 5,
  paddingTop: 2,
  paddingLeft: 10,
  position: "relative",
});

interface EditableDefinitionTranslationsProps {
  definition: DefinitionType;
  card: CardType;
  defaultProvider: string;
  onUpdate: (cardId: string, frontString: string) => void;
  onClose: () => void;
}

const MAX_TEXT_LENGTH = 1000;

export default function EditableDefinitionTranslations({
  definition,
  defaultProvider,
  card,
  onUpdate,
  onClose,
}: EditableDefinitionTranslationsProps): ReactElement {
  const [editing, setEditing] = useState(false);
  const translate = useTranslate();

  const editor = useRef<LexicalEditor>(null);
  const viewer = useRef<LexicalEditor>(null);
  const [currentFront, setCurrentFront] = useState<string>();

  useEffect(() => {
    setCurrentFront(card.front || undefined);
  }, [card]);

  function frontFromTranslations(providerTranslation: ProviderTranslationType) {
    const div = document.createElement("div");
    const root = createRoot(div);
    flushSync(() => {
      root.render(<PosItems providerEntry={providerTranslation} translate={translate} />);
    });

    const fakeDoc = document.implementation.createHTMLDocument("fakedoc");
    fakeDoc.body.appendChild(div);
    return fakeDoc;
  }

  function handleEditFromDefinition(providerTranslation: ProviderTranslationType): void {
    editor?.current?.update(() => {
      if (editor?.current) {
        const nodes = $generateNodesFromDOM(editor.current, frontFromTranslations(providerTranslation));
        // Get the RootNode from the EditorState
        const root = $getRoot();

        // Get the selection from the EditorState
        root.clear();
        root.append(...nodes);
      }
      const newCurrentFront = JSON.stringify(editor.current?.getEditorState().toJSON());
      setCurrentFront(newCurrentFront);
    });

    setEditing(true);
  }

  function onChange(editorState: EditorState) {
    if (viewer?.current) {
      viewer?.current?.setEditorState(viewer.current.parseEditorState(editorState.toJSON()));
    }
    setCurrentFront(JSON.stringify(editorState.toJSON()));
  }

  const initialConfig: InitialConfigType = {
    namespace: "meaningeditor",
    theme: lexicalEditorTheme,
    editorState: currentFront || undefined,
    onError: (message) => console.error(message),
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  return (
    <>
      <Grid
        sx={{
          padding: currentFront && !editing ? "1em" : "0",
          visibility: currentFront && !editing ? "visible" : "hidden",
          height: currentFront && !editing ? "100%" : 0,
        }}
        container
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{translate("widgets.editable_definition_translations.current_value")}</Typography>
          <Box sx={{ maxWidth: "500px" }}>
            {/* <LexicalViewer namespace="meaningtext" editorState={card.front || undefined} editorRef={viewer} /> */}
            {currentFront && <LexicalViewer namespace="meaningtext" editorState={currentFront} editorRef={viewer} />}
          </Box>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              if (viewer?.current && currentFront) {
                editor?.current?.setEditorState(
                  // viewer.current.parseEditorState(viewer?.current.getEditorState().toJSON()),
                  viewer.current.parseEditorState(currentFront),
                );
                setEditing(true);
              }
            }}
          >
            {translate("ra.action.edit")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: editing ? "1em" : "0",
          visibility: editing ? "visible" : "hidden",
          height: editing ? "100%" : 0,
        }}
        container
        justifyContent="space-between"
      >
        <Grid item sx={{ width: "80%" }}>
          <Box sx={{ maxWidth: "500px" }}>
            <LexicalComposer initialConfig={initialConfig}>
              <EditorRefPlugin editorRef={editor} />
              <ToolbarPlugin />
              <RichTextPlugin
                contentEditable={<MuiContentEditable />}
                placeholder={
                  <div style={{ position: "absolute", top: "64px" }}>
                    {translate("screens.textcrobes.type_something_here")}
                  </div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              {/* <OnChangePlugin onChange={onChange} ignoreSelectionChange /> */}
              <AutoFocusPlugin />
              <LinkPlugin />
              <ListPlugin />
              <MaxLengthPlugin maxLength={MAX_TEXT_LENGTH} />
            </LexicalComposer>
          </Box>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              if (editor.current) {
                const newEditorState = editor.current?.getEditorState();
                const newCurrentFront = JSON.stringify(newEditorState.toJSON());
                onChange(newEditorState);
                onUpdate(card.id, newCurrentFront);
                setCurrentFront(newCurrentFront);
                setEditing(false);
              }
            }}
          >
            {translate("ra.action.save")}
          </Button>
        </Grid>
      </Grid>
      {definition.providerTranslations.length &&
        definition.providerTranslations.map((providerEntry) => {
          return (
            providerEntry.posTranslations.length > 0 && (
              <Grid sx={{ padding: "1em" }} container justifyContent="space-between" key={providerEntry.provider}>
                <Grid item>
                  <Typography>{providerEntry.provider}</Typography>
                  <PosItems providerEntry={providerEntry} translate={translate} />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.stopPropagation();
                      handleEditFromDefinition(providerEntry);
                    }}
                  >
                    {translate(
                      `widgets.editable_definition_translations.${
                        providerEntry.provider === defaultProvider && !currentFront && !editing ? "edit" : "use_me"
                      }`,
                    )}
                  </Button>
                </Grid>
              </Grid>
            )
          );
        })}
      <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "flex-end", alignContent: "flex-end" }}>
        <Button sx={{ ml: "auto", padding: "0.5em" }} size="small" onClick={onClose} startIcon={<CloseIcon />}>
          {translate("ra.action.close")}
        </Button>
      </Box>
    </>
  );
}
