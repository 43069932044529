import { Theme, useMediaQuery, useTheme } from "@mui/system";
import { FunctionField, useRecordContext, useTranslate } from "react-admin";
import { PROCESSING } from "../lib/types";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import SyncIcon from "@mui/icons-material/Sync";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

export function ProcessingField({ small }: { label?: string; small?: boolean }) {
  const record = useRecordContext();
  const theme = useTheme();
  const translate = useTranslate();
  return record ? (
    <FunctionField
      source="processing"
      sx={{
        ...(record.processing === PROCESSING.NONE && {
          color: theme.palette.primary.main,
        }),
        ...(record.processing === PROCESSING.REQUESTED && {
          color: theme.palette.warning.main,
        }),
        ...(record.processing === PROCESSING.PROCESSING && {
          color: theme.palette.warning.main,
        }),
        ...(record.processing === PROCESSING.FINISHED && {
          color: theme.palette.success.main,
        }),
        ...(record.processing === PROCESSING.ERROR && {
          color: theme.palette.error.main,
        }),
      }}
      render={(record: any) => {
        if (small) {
          switch (record.processing) {
            case PROCESSING.REQUESTED:
              return <HourglassEmptyIcon />;
            case PROCESSING.PROCESSING:
              return <SyncIcon />;
            case PROCESSING.FINISHED:
              return <CheckIcon />;
            case PROCESSING.ERROR:
              return <ErrorIcon />;
            default:
              return <PanoramaFishEyeIcon />;
          }
        }
        return translate(`widgets.processing.${PROCESSING[record.processing].toLowerCase()}`);
      }}
    />
  ) : (
    <></>
  );
}
