import dayjs from "dayjs";
import { FunctionField } from "react-admin";

export default function UnixFieldAsDate({ source, shortForm }: { source: string; shortForm?: boolean }) {
  return (
    <FunctionField
      source={source}
      render={(record: any) =>
        shortForm ? dayjs.unix(record[source]).format("YYYY-MM-DD") : dayjs.unix(record[source]).toString()
      }
    />
  );
}
