import { Box } from "@mui/material";
import { ReactElement } from "react";
import { useTranslate } from "react-admin";
import { useAppSelector } from "../../../app/hooks";
import { toPosLabels } from "../../../lib/libMethods";
import { ABC_POS_SEPARATOR, TokenType } from "../../../lib/types";

type Props = { token: TokenType };

export default function POS({ token }: Props): ReactElement {
  const toLang = useAppSelector((state) => state.userData.user.toLang);
  const translate = useTranslate();
  return (
    <Box sx={{ paddingLeft: "0.3em" }}>
      <div>
        {token.pos
          ? token.pos
              .split(ABC_POS_SEPARATOR)
              .map((x) => translate(toPosLabels(x, toLang)))
              .join(ABC_POS_SEPARATOR)
          : translate("general.pos_unknown")}
      </div>
    </Box>
  );
}
