import { SelectChangeEvent, Theme, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import {
  Datagrid,
  FunctionField,
  Link,
  List,
  ReferenceField,
  SimpleShowLayout,
  SortButton,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { useAppSelector } from "../app/hooks";
import { ContentStatsAccuracyField } from "../components/ContentStatsAccuracyField";
import { ContentStatsField } from "../components/ContentStatsField";
import ContentValueField from "../components/ContentValueField";
import HelpButton from "../components/HelpButton";
import { ListEmpty } from "../components/ListEmpty";
import { ProcessingField } from "../components/ProcessingField";
import UnixFieldAsDate from "../components/UnixFieldAsDate";
import { CONTENT_TYPE, DOCS_DOMAIN } from "../lib/types";
import ActionButton from "./ActionButton";
import CacheSwitch from "./CacheSwitch";
import { ContentGoalSelector } from "./ContentGoalSelector";

function ListActions({ empty, onGoalChange }: { empty?: boolean; onGoalChange?: (event: SelectChangeEvent) => void }) {
  const translate = useTranslate();
  return (
    <TopToolbar
      sx={{
        maxHeight: "125px",
      }}
    >
      <ContentGoalSelector showResult={false} onChange={onGoalChange} />
      <Link to="/imports/create">{translate("resources.contents.import_create")}</Link>
      {!empty && <SortButton fields={["createdAt", "title", "processing"]} />}
      <HelpButton url={`//${DOCS_DOMAIN}/page/software/configure/contents/`} />
    </TopToolbar>
  );
}

function EmptyList() {
  const translate = useTranslate();
  return (
    <ListEmpty createDisabled actions={<ListActions empty />}>
      <Typography
        sx={{
          fontSize: "1.5rem",
          textAlign: "center",
        }}
        variant="body1"
      >
        <Link to="/imports">{translate("resources.contents.empty_list")}</Link>
      </Typography>
    </ListEmpty>
  );
}

function ContentExpand() {
  const translate = useTranslate();
  return (
    <SimpleShowLayout>
      <ContentStatsField label={translate("resources.contents.contentStats")} />
      <ReferenceField source="theImport" reference="imports" link="show">
        <TextField source="title" />
      </ReferenceField>
      <FunctionField
        source="contentType"
        label={translate("resources.contents.contentType")}
        render={(record: any) => translate(`widgets.content_type.${CONTENT_TYPE[record.contentType].toLowerCase()}`)}
      />
      <UnixFieldAsDate source="createdAt" />
    </SimpleShowLayout>
  );
}

export default function ContentList() {
  const user = useAppSelector((state) => state.userData);
  const translate = useTranslate();
  const [userListId, setUserListId] = useState<string | undefined>(undefined);
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  function onGoalChange(event: SelectChangeEvent) {
    setUserListId(event.target.value);
  }
  return (
    <List
      queryOptions={{ refetchInterval: 5000 }}
      empty={<EmptyList />}
      actions={<ListActions onGoalChange={onGoalChange} />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="show" expand={<ContentExpand />} expandSingle isRowExpandable={() => isSmall}>
        <TextField source="title" />
        {!isSmall && (
          <ReferenceField source="theImport" reference="imports" link="show">
            <TextField source="title" />
          </ReferenceField>
        )}
        <ProcessingField
          label={translate("resources.contents.processingStatus" + (isSmall ? "Small" : ""))}
          small={isSmall}
        />
        {!isSmall && <UnixFieldAsDate source="createdAt" shortForm={isSmall} />}
        {!isSmall && (
          <FunctionField
            source="contentType"
            label={translate("resources.contents.fields.contentType" + (isSmall ? "Small" : ""))}
            render={(record: any) =>
              translate(`widgets.content_type.${CONTENT_TYPE[record.contentType].toLowerCase()}`)
            }
          />
        )}
        {!isSmall && <ContentStatsField label={translate("resources.contents.contentStats")} />}
        {user.showResearchDetails && <ContentStatsAccuracyField label="Accuracy" />}
        {userListId && <ContentValueField label={translate("resources.contents.value")} userlistId={userListId} />}
        <ActionButton label={translate("resources.contents.action" + (isSmall ? "Small" : ""))} />
        <CacheSwitch label={translate("resources.contents.offline" + (isSmall ? "Small" : ""))} />
      </Datagrid>
    </List>
  );
}
